<template>
  <div class="zhongguancunIndustry_pop_up">
    <div :class="[{ 'size': $store.state.zhongguancunIndustry.popUpFlag }]" class="pop_up">
      <div class="close_btn_wrap">
        <div @click="$store.commit('industryClosePop')" class="close_btn"></div>
      </div>
      <div class="cont_box">
        <h4>{{ $store.state.zhongguancunIndustry.popUpCont.company_name }}
        </h4>
        <!-- <span>产业方向:{{$store.state.zhongguancunIndustry.popUpCont.type}}</span> -->
        <p>{{ $store.state.zhongguancunIndustry.popUpCont.company_info }}</p>
      </div>
      <div class="zhongguancunIndustry-content">
        <div class="zhongguancunIndustry-left">
          <div class="zhongguancunIndustry-left-cont">
            <ZhongguancunTitle :txt="'行业'" />
            <template v-if="$store.state.zhongguancunIndustry.popUpCont.company_industry.length > 0">
              <div class="left_txt_title">
                <p>行业类型：
                  <el-select v-model="typeValue" placeholder="请选择" size="small" :popper-append-to-body="false">
                    <el-option v-for="item in $store.state.zhongguancunIndustry.popUpCont.company_industry"
                      :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </p>
              </div>
              <div class="left_cont_middle">
                <div class="left_cont_middle_left">
                  <el-radio-group v-model="radioValue" size="mini" fill="#2CD09E">
                    <el-radio-button label="comprehensive">综合实力</el-radio-button>
                    <el-radio-button label="independent">自主研发</el-radio-button>
                  </el-radio-group>
                </div>
                <div class="left_cont_middle_right">
                  <p style="font-size:18px;color:#E93A46">
                    {{ grade($store.state.zhongguancunIndustry.popUpCont.grade) && grade($store.state.zhongguancunIndustry.popUpCont.grade)[radioValue].grade }}
                  </p>
                  <div class="left_cont_middle_right_bottom">
                    <p>
                      (超出同行<span>{{ grade($store.state.zhongguancunIndustry.popUpCont.grade) && (grade($store.state.zhongguancunIndustry.popUpCont.grade)[radioValue].proportion * 100).toFixed(2) }}%</span>)
                    </p>
                  </div>
                </div>
              </div>
              <MainContentRight
                :strengthData="strength($store.state.zhongguancunIndustry.popUpCont.tech_rd_strength)" />
            </template>
            <div v-else class="left_nodata">
              <img src="@/assets/images/word_cloud_nodata_zgc.png" alt="" />
            </div>
          </div>
          <div class="zhongguancunIndustry-left-middle">
            <ZhongguancunTitle :txt="'技术质量'" />
            <div class="left_txt_other"
              v-if="$store.state.zhongguancunIndustry.popUpCont.patent_star && $store.state.zhongguancunIndustry.popUpCont.patent_star.length > 0">
              <p style="font-size:14px;">发明专利：<span
                  style="font-size:24px;color:#45E8B5;">{{ $store.state.zhongguancunIndustry.popUpCont.intellectual_property_statistics.independent_rd_invention_patent_num }}</span>
                件，占比 <span
                  style="font-size:24px;color:#45E8B5;">{{ $store.state.zhongguancunIndustry.popUpCont.intellectual_property_statistics.independent_rd_invention_patent_num ? (($store.state.zhongguancunIndustry.popUpCont.intellectual_property_statistics.independent_rd_invention_patent_num / $store.state.zhongguancunIndustry.popUpCont.intellectual_property_statistics.invention_patent_num) * 100).toFixed(2) : 0 }}</span>%，
                软著：<span
                  style="font-size:24px;color:#45E8B5;">{{ $store.state.zhongguancunIndustry.popUpCont.intellectual_property_statistics.software_copyright_num }}</span>
                项
              </p>
              <p>实用新型：<span
                  style="font-size:24px;color:#45E8B5;">{{ $store.state.zhongguancunIndustry.popUpCont.intellectual_property_statistics.independent_rd_utility_model_patent_num }}</span>
                件，占比 <span
                  style="font-size:24px;color:#45E8B5;">{{ $store.state.zhongguancunIndustry.popUpCont.intellectual_property_statistics.independent_rd_utility_model_patent_num ? (($store.state.zhongguancunIndustry.popUpCont.intellectual_property_statistics.independent_rd_utility_model_patent_num / $store.state.zhongguancunIndustry.popUpCont.intellectual_property_statistics.utility_model_patent_num) * 100).toFixed(2) : 0 }}</span>%
              </p>
              <div class="PieChat1">
                <BottomPieChart />
                <div class="PieChat1-item">
                  <div class="PieChat1-wrag">
                    <p><span
                        style="font-size:14px;color:#43E8B6;">{{ $store.state.zhongguancunIndustry.popUpCont.patent_star.length > 0 ? $store.state.zhongguancunIndustry.popUpCont.patent_star[5].patent_num : 0 }}</span>
                      件，占比 <span
                        style="font-size:14px;color:#43E8B6;">{{ $store.state.zhongguancunIndustry.popUpCont.patent_star.length > 0 ? ($store.state.zhongguancunIndustry.popUpCont.patent_star[5].patent_num_proportion * 100).toFixed(2) : 0 }}</span>%
                    </p>
                  </div>
                  <div class="PieChat1-wrag">
                    <p><span
                        style="font-size:14px;color:#43E8B6;">{{ $store.state.zhongguancunIndustry.popUpCont.patent_star.length > 0 ? $store.state.zhongguancunIndustry.popUpCont.patent_star[4].patent_num : 0 }}</span>
                      件，占比 <span
                        style="font-size:14px;color:#43E8B6;">{{ $store.state.zhongguancunIndustry.popUpCont.patent_star.length > 0 ? ($store.state.zhongguancunIndustry.popUpCont.patent_star[4].patent_num_proportion * 100).toFixed(2) : 0 }}</span>%
                    </p>
                  </div>
                  <div class="PieChat1-wrag">
                    <p><span
                        style="font-size:14px;color:#43E8B6;">{{ $store.state.zhongguancunIndustry.popUpCont.patent_star.length > 0 ? $store.state.zhongguancunIndustry.popUpCont.patent_star[3].patent_num : 0 }}</span>
                      件，占比 <span
                        style="font-size:14px;color:#43E8B6;">{{ $store.state.zhongguancunIndustry.popUpCont.patent_star.length > 0 ? ($store.state.zhongguancunIndustry.popUpCont.patent_star[3].patent_num_proportion * 100).toFixed(2) : 0 }}</span>%
                    </p>
                  </div>
                  <div class="PieChat1-wrag">
                    <p><span
                        style="font-size:14px;color:#43E8B6;">{{ $store.state.zhongguancunIndustry.popUpCont.patent_star.length > 0 ? $store.state.zhongguancunIndustry.popUpCont.patent_star[2].patent_num : 0 }}</span>
                      件，占比 <span
                        style="font-size:14px;color:#43E8B6;">{{ $store.state.zhongguancunIndustry.popUpCont.patent_star.length > 0 ? ($store.state.zhongguancunIndustry.popUpCont.patent_star[2].patent_num_proportion * 100).toFixed(2) : 0 }}</span>%
                    </p>
                  </div>
                  <div class="PieChat1-wrag">
                    <p><span
                        style="font-size:14px;color:#43E8B6;">{{ $store.state.zhongguancunIndustry.popUpCont.patent_star.length > 0 ? $store.state.zhongguancunIndustry.popUpCont.patent_star[1].patent_num : 0 }}</span>
                      件，占比 <span
                        style="font-size:14px;color:#43E8B6;">{{ $store.state.zhongguancunIndustry.popUpCont.patent_star.length > 0 ? ($store.state.zhongguancunIndustry.popUpCont.patent_star[1].patent_num_proportion * 100).toFixed(2) : 0 }}</span>%
                    </p>
                  </div>
                </div>
                <div class="PieChat1-img">
                  <img src="../../assets/images/ring2.png" />
                </div>
              </div>
            </div>
            <div v-else class="left_txt_other_nodata">
              <img src="@/assets/images/word_cloud_nodata_zgc.png" alt="" />
            </div>
          </div>
        </div>
        <div class="zhongguancunIndustry-right">
          <div class="zhongguancunIndustry-right-footer">
            <ZhongguancunTitle :txt="'主要技术'" />
            <WordCloud noDataIcon="zgc" :wordCloudData="$store.state.zhongguancunIndustry.popUpCont.company_keywords" />
          </div>
          <div class="zhongguancunIndustry-right-chart">
            <ZhongguancunTitle :txt="'研发效率'" />
            <template
              v-if="$store.state.zhongguancunIndustry.popUpCont.distribution && $store.state.zhongguancunIndustry.popUpCont.distribution.length > 0">
              <div class="zhongguancunIndustry_chart_text">
                <p style="font-size:14px;">该企业近三年的研发效率平均值<span
                    style="font-size:14px;color:#FD6B44;">({{ $store.state.zhongguancunIndustry.popUpCont.rd_efficiency ? ($store.state.zhongguancunIndustry.popUpCont.rd_efficiency.recent_years_company_rd_efficiency_avg).toFixed(2) : 0 }})</span>
                </p>
                <p style="font-size:14px;">
                  {{ $store.state.zhongguancunIndustry.popUpCont.rd_efficiency && ($store.state.zhongguancunIndustry.popUpCont.rd_efficiency.recent_years_company_rd_efficiency_avg * 100)
                      >
                      ($store.state.zhongguancunIndustry.popUpCont.rd_efficiency.recent_years_cluster_rd_efficiency_avg * 100)
                      ?
                      '高于' : ($store.state.zhongguancunIndustry.popUpCont.rd_efficiency.recent_years_company_rd_efficiency_avg * 100)
                        ==
                        ($store.state.zhongguancunIndustry.popUpCont.rd_efficiency.recent_years_cluster_rd_efficiency_avg * 100) ? '等于' : ($store.state.zhongguancunIndustry.popUpCont.rd_efficiency.recent_years_company_rd_efficiency_avg * 100)
                          < ($store.state.zhongguancunIndustry.popUpCont.rd_efficiency.recent_years_cluster_rd_efficiency_avg * 100) ? '低于' : ''
                  }}同行业研发效率平均值<span
                    style="font-size:14px;color:#FDA14B;">
                    ({{ $store.state.zhongguancunIndustry.popUpCont.rd_efficiency ? ($store.state.zhongguancunIndustry.popUpCont.rd_efficiency.recent_years_cluster_rd_efficiency_avg).toFixed(2) : 0 }})</span>
                </p>
              </div>
              <div class="zhongguancunIndustry-PieChat2">
                <BottomLineChart />
              </div>
            </template>
            <div v-else class="zhongguancunIndustry-right-chart-nodata">
              <img src="@/assets/images/word_cloud_nodata_zgc.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZhongguancunTitle from '@/components/ZhongguancunTitle.vue'
import MainContentRight from '@/components/zhongguancunIndustry/MainContentRight.vue';
import BottomLineChart from './BottomLineChart.vue';
import BottomPieChart from './BottomPieChart.vue';
import WordCloud from '@/components/WordCloud.vue';

export default {
  data() {
    return {
      radioValue: 'comprehensive',
      typeValue: '',
    }
  },
  computed: {
    grade() {
      const gradeName = {
        0: 'A',
        1: 'B',
        2: 'C',
        3: 'D',
        4: 'E'
      }
      return function (val) {
        return val.find((value) => {
          value.independent = {
            grade: gradeName[value.independent_rd_grade],
            proportion: value.independent_rd_exceed_proportion
          }
          value.comprehensive = {
            grade: gradeName[value.comprehensive_strength_grade],
            proportion: value.comprehensive_strength_exceed_proportion
          }
          return this.typeValue == value.industry_id
        })
      }
    },
    strength() {
      return function (val) {
        return val.find((value) => {
          return this.typeValue == value.industry_id
        })
      }
    }
  },
  components: {
    ZhongguancunTitle,
    MainContentRight,
    BottomPieChart,
    BottomLineChart,
    WordCloud,
  },
  methods: {
    defaultSelect() {
      const opt = this.$store.state.zhongguancunIndustry.popUpCont.company_industry
      if (opt.length > 0) {
        this.typeValue = opt[0].value
      }
    },
  },
  mounted() {
    this.defaultSelect()
  },
}
</script>

<style lang="less">
.zhongguancunIndustry_pop_up {

  // .el-radio_input.is-checked + .el-radion_label{
  //   color: #43e8b6 !important;
  // }
  // .container{
  // /*双击文字、input等，出现的蓝色底色，如何去除*/
  // -moz-user-select:none;/*火狐*/
  // -webkit-user-select:none;/*webkit浏览器*/
  // -ms-user-select:none;/*IE10*/
  // /*-khtml-user-select:none;!*早期浏览器*!*/
  // user-select:none;
  // }
  // .el-radio_input.is-checked .el-radio_inner{
  //   background: #43e8b6 !important;
  //   border-color: #43e8b6 !important;
  // }
  .size {
    transform: scale(1) !important;
  }

  .noneAmin {
    transform: scale(1) !important;
    transition: 0s !important;
  }

  position: relative;

  .pop_up {
    transform: scale(0);
    transition: .5s;
    position: absolute;
    top: 130px;
    left: 530px;
    width: 854px;
    border-radius: 20px;
    border: 1px solid #4dddac;
    background: rgba(1, 15, 11, .7);
    z-index: 9999;
  }

  .close_btn_wrap {
    display: flex;
    justify-content: flex-end;

    .close_btn {
      cursor: pointer;
      width: 30px;
      height: 30px;
      margin-right: 14px;
      margin-top: 16px;
      background: url(../../assets/images/zhongguancunClose.png) no-repeat;
    }
  }

  .cont_box {
    margin-top: -40px !important;

    h4,
    span {
      text-align: center;
      display: block;
    }

    h4 {
      font-size: 20px;
      font-weight: 400;
    }

    span {
      color: #43e8b6;
      margin-top: 3px;
    }

    p {
      padding: 13px 33px;
      line-height: 22px;
      text-align: justify;
    }

    // span{
    //   text-align: center;
    // }
  }

  .zhongguancunIndustry_pop_content {
    position: relative;
    padding: 0 21px;
    display: flex;
  }

  .zhongguancunIndustry-content {
    display: flex;
    justify-content: space-between;

    .zhongguancunIndustry-left {
      min-width: 380px;

      .zhongguancunIndustry-left-cont {
        margin-left: 10px;
        margin-bottom: 20px;

        .left_txt_title p {
          margin-top: 15px;
          margin-left: 15px;

          .el-select .el-input__inner:focus,
          .el-range-editor.is-active,
          .el-range-editor.is-active:hover,
          .el-select .el-input.is-focus .el-input__inner {
            border-color: #349776;
          }

          .el-select .el-input .el-select__caret {
            color: #ffffff;
            font-weight: bold;
          }

          .el-select-dropdown {
            background-color: #010b08;
            border: 1px solid #349776;
          }

          .popper__arrow::after {
            border-bottom-color: #43e8b6 !important
          }

          .el-select-dropdown__item.selected {
            color: #ffffff;
          }

          .el-select-dropdown__item {
            color: #02918f;
            font-weight: bold;
          }

          .el-select-dropdown__item.hover,
          .el-select-dropdown__item:hover {
            background-color: #2cd09e
          }
        }

        .left_cont_middle {
          display: flex;
          justify-content: space-between;
          margin: 0 20px;
          margin-top: 18px;

          .left_cont_middle_left {
            display: flex;
            align-items: center;
            height: 70px;

            .el-radio-button__inner {
              background: transparent;
              color: #FFFFFF;
            }
          }

          .left_cont_middle_right {
            text-align: center;

            .left_cont_middle_right_bottom {
              width: 130px;
              height: 20px;
              background: #EB515C;
            }
          }
        }
      }

      .zhongguancunIndustry-left-middle {
        margin-left: 10px;

        .left_txt_other {
          margin-top: 18px;

          span {
            font-family: 'bigdsfont';
          }
        }

        .left_txt_other_nodata {
          width: 100%;
          height: 338px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 160px;
            height: 110px;
          }
        }

        .PieChat1 {
          // margin-left: -50px;
          display: flex;
          position: relative;
          margin-left: -100px;
          padding-bottom: 30px;

          .PieChat1-img {
            position: absolute;
            top: 30px;
            left: 125px;
          }

          .PieChat1-item {
            margin-top: 20px;
            margin-left: -34px;
          }

          .PieChat1-wrag {
            padding-bottom: 5px;
            display: flex
          }
        }

      }
    }

    .zhongguancunIndustry-right {
      margin-left: 30px;

      .zhongguancunIndustry-right-chart {
        margin-top: 25px;

        .zhongguancunIndustry_chart_text {
          margin-top: 17px;
          margin-left: 5px;
        }

        .zhongguancunIndustry-PieChat2 {
          margin-top: 30px;
        }
      }

      .zhongguancunIndustry-right-chart-nodata {
        width: 430px;
        height: 360px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 160px;
          height: 110px;
        }
      }

    }
  }
}

.left_nodata {
  width: 100%;
  height: 246px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 160px;
    height: 110px;
  }
}
</style>