<template>
    <div id="myChart" :style="{width: '100%', height: '250px'}"></div>
</template>
<script>
    export default {
        data () {
            return {

            }
        },
        mounted () {
            // this.drawLine();
            this.drawLine()
        },
        methods: {
            drawLine(){
                // 基于准备好的dom，初始化echarts实例
                let that = this
                let myChart = this.$echarts.init(document.getElementById('myChart'))
                // 绘制图表
                myChart.setOption({
                  legend: {
                    orient: 'vertical',
                    x: '300',
                    y: 'center',
                    align: 'left',
                    padding:[0,0,0,0],
                    itemHeight: 6,
                    itemWidth: 6,
                    textStyle: {
                      color: '#fff',
                    },
                    formatter: function(industry_type) {
                      // var index = 0;
                      var clientlabels = that.$store.state.home.allAtlas;
                      // var clientcounts = that.$store.state.home.allAtlas;
                      clientlabels.forEach(function(value){
                          if(value.industry_type == industry_type){
                              // index = i;
                          }
                      });
                      return industry_type;
                      // return industry_type + "  " + '(' + clientcounts[index].value + ')';
                    }
                    // this.$store.state.home.allAtlas
                    // formatter: function(name) {
                    //   var data = setOption.series[0].data;
                    //   var total = 0;
                    //   var tarValue;
                    //   for (var i = 0; i < data.length; i++) {
                    //     total += data[i].value;
                    //     if (data[i].name == name) {
                    //       tarValue = data[i].value;
                    //     }
                    //   }
                    //   var v = tarValue;
                    //   var p = Math.round(((tarValue / total) * 100));
                    //   return `${name}  ${v}人(${p}%)`;
                    // },
                  },
                  backgroundColor: '',
                  title: {
                    text: '',
                    left: 'center',
                    top: 20,
                    textStyle: {
                      color: ''
                    }
                  },
                  tooltip: {
                    trigger: 'item',
                    color: '#fff'
                  },
                  visualMap: {
                    show: false,
                    // min: 0,
                    // max: 30,
                    inRange: {
                      // colorLightness: [0, 1]
                    }
                  },
                  color: [
                        '#f1cd6e',
                        '#f59a27',
                        '#2fb1df',
                        '#2b85f4',
                        '#43e8b6',
                  ],
                  series: [
                    {
                      
                      name: '',
                      type: 'pie',
                      radius: [55, 75],
                      center: ['36.5%', '48%'],
                      data: this.$store.state.zhongguancunIndustry.enterpriseEchartsArr,
                      /* data: [
                        { value: this.$store.state.home.allAtlas[0].company_num, name: this.$store.state.home.allAtlas[0].industry_type },
                        { value: this.$store.state.home.allAtlas[1].company_num, name: this.$store.state.home.allAtlas[1].industry_type },
                        { value: this.$store.state.home.allAtlas[2].company_num, name: this.$store.state.home.allAtlas[2].industry_type },
                        { value: this.$store.state.home.allAtlas[3].company_num, name: this.$store.state.home.allAtlas[3].industry_type },
                        { value: this.$store.state.home.allAtlas[4].company_num, name: this.$store.state.home.allAtlas[4].industry_type },
                        // { value: this.$store.state.home.allAtlas[5].company_num, name: this.$store.state.home.allAtlas[5].industry_type },
                        
                        // 医药制造（7）；生物技术（8）、医疗器械（2）、数字健康（1）、材料（1）、化工（1）
                      ], */
                      // roseType: 'radius',
                      label: {
                        normal: {
                          textStyle: {
                              color: '#fff',
                          },
                          formatter: `{c}家`
                        },
                      },
                      
                      labelLine: {
                        lineStyle: {
                          // color: 'rgba(255, 255, 255, 0.3)'
                        },
                        smooth: 0.2,
                        length: 20,
                        length2: 25
                      },
                      itemStyle: {
                        // color: 'green',
                        // shadowBlur: 200,
                        // shadowColor: 'rgba(0, 0, 0, 0.5)'
                      },
                       
                      animationType: 'scale',
                      animationEasing: 'elasticOut',
                      animationDelay: function () {
                        return Math.random() * 200;
                      }
                    },
                  ]
                });
            },
        }
    }
</script>