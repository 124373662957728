<template>
  <div id="ZGCBottomPieChart" :style="{width: '350px', height: '160px'}"> </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    drawLine() {
      if (!this.$store.state.zhongguancunIndustry.popUpCont.patent_star || this.$store.state.zhongguancunIndustry.popUpCont.patent_star.length == 0) return
      let pieChart = this.$echarts.init(document.getElementById('ZGCBottomPieChart'))
      let infoData = [
        { value: this.$store.state.zhongguancunIndustry.popUpCont.patent_star[5].patent_num, name: '5星专利' },
        { value: this.$store.state.zhongguancunIndustry.popUpCont.patent_star[4].patent_num, name: '4星专利' },
        { value: this.$store.state.zhongguancunIndustry.popUpCont.patent_star[3].patent_num, name: '3星专利' },
        { value: this.$store.state.zhongguancunIndustry.popUpCont.patent_star[2].patent_num, name: '2星专利' },
        { value: this.$store.state.zhongguancunIndustry.popUpCont.patent_star[1].patent_num, name: '1星专利' },
      ]
      let option = {
        tooltip: {
          trigger: 'item',
          confine: true
        },
        title: {
          text: '{b|技术评级}',
          textStyle: {
            rich: {
              b: {
                fontSize: "16",
                color: "#fff",
              }
            }
          },
          itemGap: 10,
          left: 'center',
          top: "center"
        },
        legend: {
          orient: 'vertical',
          x: '240',
          y: '20',
          align: 'left',
          itemHeight: 10,
          itemWidth: 10,
          itemGap: 10,
          textStyle: {
            color: '#fff',
            fontSize: '14',
          },
        },
        color: ['#EA8937', '#F59A27', '#2FB1DF', '#2B85F4', '#43E8B6'],
        series: [{
          name: 'Access From',
          type: 'pie',
          radius: ['80%', '65%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'outer',
            alignTo: 'labelLine',
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          itemStyle: {
            borderRadius: 0,
          },
          data: infoData,
        }],
      }
      pieChart.setOption(option)
    },
  },
  mounted() {
    this.drawLine()
  }
}
</script>