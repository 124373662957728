<template>
  <div>
    <div id="MainContentRight" :style="{width: '360px', height: '215px'}">
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      chartOpt: {
        legend: {
          data: ['企业自主研发', '行业平均自主研发'],
          top: '60%',
          left: '65%',
          show: true,
          orient: 'vertical',
          itemHeight: 6,
          itemWidth: 6,
          textStyle: {
            color: '#fff',
          }
        },
        radar: {
          indicator: [
            { name: '技术布局', max: 100 },
            { name: '技术评级', max: 100 },
            { name: '技术影响', max: 100 },
            { name: '技术迭代率', max: 100 },
            { name: '技术研发效率', max: 100 },
          ],
          center: ['40%', '60%'],
        },
        color: [
          '#2176e4',
          '#4bc9a4',
        ],
        series: [
          {
            symbolSize: 0,//拐点
            type: 'radar',
            data: [
              {
                value: [],
                name: '行业平均自主研发',
                areaStyle: {
                  opacity: 0.5, // 图表中各个图区域的透明度
                  color: "#4bc9a4", // 图表中各个图区域的颜色
                  center: ['10%', '80%'],
                }
              },
              {
                value: [],
                name: '企业自主研发',
                areaStyle: {
                  opacity: 0.5, // 图表中各个图区域的透明度
                  color: "#2176e4" // 图表中各个图区域的颜色
                }
              },
            ]
          }
        ]
      }
    }
  },
  props: {
    strengthData: {
      default: () => null
    },
  },
  watch: {
    strengthData: {
      handler(value) {
        if (value && this.myChart) {
          value.industry = value.industry_tech_strength.map((item) => (item.value * 100).toFixed(2))
          value.company = value.company_tech_strength.map((item) => (item.value * 100).toFixed(2))
          const chartOpt = {
            series: [
              {
                symbolSize: 0,
                type: 'radar',
                data: [
                  {
                    value: value.industry,
                    name: '行业平均自主研发',
                    areaStyle: {
                      opacity: 0.5,
                      color: "#2176e4",
                      center: ['10%', '80%'],
                    }
                  },
                  {
                    value: value.company,
                    name: '企业自主研发',
                    areaStyle: {
                      opacity: 0.5,
                      color: "#4bc9a4"
                    }
                  },
                ]
              }
            ]
          }
          this.myChart.setOption({ ...this.chartOpt, ...chartOpt })
        }
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    drawLine() {
      this.myChart = this.$echarts.init(document.getElementById('MainContentRight'))
      this.myChart.setOption(this.chartOpt)
    }
  },
  mounted() {
    this.drawLine()
  },
}
</script>