<template>
  <div id="ZGCBottomLineChart" :style="{width: '450px', height: '260px'}"></div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    drawLine() {
      if (!this.$store.state.zhongguancunIndustry.popUpCont.distribution || this.$store.state.zhongguancunIndustry.popUpCont.distribution.length == 0) return
      let datetime = [], company = [], industry = [];
      this.$store.state.zhongguancunIndustry.popUpCont.distribution.map((item) => {
        datetime.push(String(item.date_time))
        company.push(item.company_rd_efficiency)
        industry.push(item.cluster_rd_efficiency)
      })
      let lineChart = this.$echarts.init(document.getElementById('ZGCBottomLineChart'))
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          }
        },
        color: ['#EA8937', '#FC4F2D'],
        legend: {
          itemHeight: 6,
          itemWidth: 6,
          x: 'center',
          y: 'top',
          textStyle: {
            fontSize: 14,
            color: '#ffffff'
          },
          data: [
            { name: '企业自主研发', icon: 'rect' },
            { name: '行业平均自主研发', icon: 'rect' }]
        },
        grid: {
          left: '4%',
          right: '13%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
            data: datetime,
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            boundaryGap: false,
            nameTextStyle: {
              color: "#ffffff",
              fontSize: 12,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: 'rgb(67,71,87)'
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff'
              }
            }
          }
        ],
        series: [
          {
            name: '企业自主研发',
            type: 'line',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            data: company
          },
          {
            name: '行业平均自主研发',
            type: 'line',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            data: industry
          },
        ]
      };
      lineChart.setOption(option)
    },
  },
  mounted() {
    this.drawLine()
  }
}
</script>