<template>
  <div class="zhongguancun_right_content">
    <ZhongguancunTitle :txt="'园区企业'" />
    <div class="delegate_list_wrap">
      <ul class="delegate_list">
        <li :class="[{ active: $store.state.zhongguancunIndustry.industryActive === index }]"
          v-for="(item, index) in list" :key="index" @click="detailBtn(item, index)">
          <img src="@/assets/images/zhongguancunIndustryRightIcon.png" alt="">
          <div class="delegate_list_top">
            <h3>{{ item.company_name }}</h3>
          </div>
          <!-- <div class="delegate_list_bottom">
            <span @click="detailBtn(item,index)">[详情]</span>
          </div> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ZhongguancunTitle from '@/components/ZhongguancunTitle.vue'
import { getCompanyList } from '@/api/zgc';
export default {
  data() {
    return {
      list: {}
    }
  },
  components: {
    ZhongguancunTitle
  },
  methods: {
    getData() {
      getCompanyList({
        type: 3,
        limit: 999
      }).then((res) => {
        console.log('中关村企业列表', res)
        const { data } = res;
        data.map((item) => {
          const company_industry = [];
          item.company_keywords = item.company_keywords ? JSON.parse(item.company_keywords) : [];
          item.patent_star = item.patent_star ? JSON.parse(item.patent_star) : null;
          item.intellectual_property_statistics = item.intellectual_property_statistics ? JSON.parse(item.intellectual_property_statistics) : null
          item.rd_efficiency = item.rd_efficiency ? JSON.parse(item.rd_efficiency) : null
          item.distribution = item.distribution ? JSON.parse(item.distribution) : []
          item.company_industry = item.company_industry ? JSON.parse(item.company_industry) : []
          item.company_industry.map((item) => {
            if (item.children && item.children.length > 0) {
              item.children.map((jtem) => {
                company_industry.push({
                  value: jtem.industry_id,
                  label: jtem.industry_name
                })
              })
            }
          })
          item.company_industry = company_industry
          item.grade = item.grade ? JSON.parse(item.grade) : []
          item.tech_rd_strength = item.tech_rd_strength ? JSON.parse(item.tech_rd_strength) : []
        })
        this.list = data
      })
    },
    detailBtn(item, index) {
      console.log(item)
      this.$store.commit('industryDetail', { item, index })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
.zhongguancun_right_content {
  .delegate_list>li {
    cursor: pointer;
  }
}
</style>