<template>
    <div class="BusinessEcharts_wrap">
      <!-- {{$store.state.home.allAtlas}} -->
      <div class="BusinessEcharts_bg"></div>
      <div id="myChart2" :style="{width: '480px', height: '250px'}"></div>
      <!-- {{$store.state.zhongguancunIndustry.businessEchartsArr}} -->
    </div>
</template>
<script>
import * as echarts from 'echarts'

    export default {
        data () {
            return {

            }
        },
        mounted () {
            // this.drawLine();
            this.drawLine()
        },
        methods: {
            drawLine(){
                // 基于准备好的dom，初始化echarts实例
                let that = this
                let myChart2 = this.$echarts.init(document.getElementById('myChart2'))
                // 绘制图表
                myChart2.setOption({
                  legend: {
                    orient: 'vertical',
                    x: '300',
                    y: 'center',
                    align: 'left',
                    padding:[0,0,0,0],
                    itemHeight: 6,
                    itemWidth: 6,
                    
                    textStyle: {
                      color: '#fff',
                    },
                    formatter: function(industry_type) {
                      // var index = 0;
                      var clientlabels = that.$store.state.zhongguancunIndustry.businessEchartsArr;
                      // var clientcounts = that.$store.state.zhongguancunIndustry.businessEchartsArr;
                      clientlabels.forEach(function(value){
                          if(value.industry_type == industry_type){
                              // index = i;
                          }
                      });
                      // return industry_type + "  " + '(' + clientcounts[index].value + ')';
                      return industry_type;
                    }
                  },
                  // backgroundColor: '',
                  // borderRadius: 10,
                  title: {
                    text: '',
                    left: 'center',
                    top: 20,
                    textStyle: {
                      color: ''
                    }
                  },
                  tooltip: {
                    trigger: 'item',
                    color: '#fff'
                  },
                  visualMap: {
                    show: false,
                    // min: 0,
                    // max: 30,
                    inRange: {
                      // colorLightness: [0, 1]
                    }
                  },
                  color: [
                    // '#f1cd6e',
                    // '#f59a27',
                    // '#2fb1df',
                    // '#2b85f4',
                    // '#43e8b6',
                      new echarts.graphic.LinearGradient(1,1,0,0, [{
                          offset:0.4,
                          color: '#f0cd6f'
                      },
                      {
                          offset: 0.8,
                          color: 'rgba(240,205,111,0.6)'
                      },
                      {
                          offset: 1,
                          color: 'transparent'
                      }]),
                      new echarts.graphic.LinearGradient(0,1,1,0, [{
                          offset:0.4,
                          color: '#f49b28'
                      },
                      {
                          offset: 0.8,
                          color: 'rgba(244,155,40,0.6)'
                      },
                      {
                          offset: 1,
                          color: 'transparent'
                      }]),
                      new echarts.graphic.LinearGradient(0,1,1,0, [{
                          offset:0.4,
                          color: '#2fb2de'
                      },
                      {
                          offset: 0.8,
                          color: 'rgba(47,178,222,0.6)'
                      },
                      {
                          offset: 1,
                          color: 'transparent'
                      }]),
                      new echarts.graphic.LinearGradient(0,0,1,1, [{
                          offset:0.4,
                          color: '#2b86f3'
                      },
                      {
                          offset: 0.8,
                          color: 'rgba(43,134,234,0.6)'
                      },
                      {
                          offset: 1,
                          color: 'transparent'
                      }]),
                      new echarts.graphic.LinearGradient(1,0,0,1, [{
                          offset:0.4,
                          color: '#46e8b5'
                      },
                      {
                          offset: 0.8,
                          color: 'rgba(70,232,181,0.6)'
                      },
                      {
                          offset: 1,
                          color: 'transparent'
                      }]),
                    ],
                  // color: [
                  //   // 'rgba(92, 106, 229, .5)',
                  //   '#5c6ae5',
                  //   '#dd7077',
                  //   '#2182ff',
                  //   '#edb76e',
                  //   '#51dbb2',
                  //   '#a969c6',
                  // ],
                  series: [
                    {
                      
                      name: '1',
                      type: 'pie',
                      radius: [70, 80],
                      center: ['33%', '46%'],
                      data: that.$store.state.zhongguancunIndustry.businessEchartsArr,
                      
                      label: {
                        normal: {
                          textStyle: {
                              color: '#fff',
                          },
                          formatter: `{c}家`
                          // b：标题，c：数量
                          // formatter: `{b} ({c})`
                        },
                      },
                      
                      labelLine: {
                        lineStyle: {
                          // color: 'rgba(255, 255, 255, 0.3)'
                        },
                        smooth: 0.2,
                        length: 10,
                        length2: 20
                      },
                      itemStyle: {
                        normal: {
                          borderRadius: 20,
                          // color: [
                          // '#f1cd6e',
                          // '#f59a27',
                          // '#2fb1df',
                          // '#2b85f4',
                          // '#43e8b6',
                          // ],
                          // color: 'red',
                          // color0: 'pink'
                        }
                      },
                       
                      animationType: 'scale',
                      animationEasing: 'elasticOut',
                      animationDelay: function () {
                        return Math.random() * 200;
                      }
                    },
                  ]
                });
            },
        }
    }
</script>
<style lang="less">
  .BusinessEcharts_wrap{
    position: relative;
    .BusinessEcharts_bg{
      position: absolute;
      top: 17px;
      left: 61px;
      width: 200px;
      height: 200px;
      background: url(../../assets/images/zhongguancunEchartBg3.png) no-repeat;
    }
  }
</style>