<template>
  <div class="zhongguancun_left_content">
    <div class="top">
      <ZhongguancunTitle :txt="'园区入驻概况'" />
      <ul class="settle_list">
        <li v-for="(item, index) in settleArr" :key="index">
          <HomeIcon :width="45" :height="45" :absoluteValue="47" :src="item.cover" :color="item.color"></HomeIcon>
          <div class="settle_cont">
            <div class="num_box">
              <ICountUp v-if="item.num" class="num" :style="{color: item.color}"
                :delay="$store.state.overView.ICountUpOption.delay" :endVal="item.num"
                :options="{separator: $store.state.overView.ICountUpOption.separator}" />
            </div>
            <h4>{{item.title}}<span>({{item.company}})</span></h4>
          </div>
        </li>
      </ul>
    </div>
    <div class="center">
      <ZhongguancunTitle :txt="'行业分类'" />
      <div class="top_box">
        <div class="top_bg"></div>
        <h5>企业行业分类</h5>
        <EnterpriseEcharts v-if="showFlag" />
      </div>
      <div class="bottom_box">
        <h5>商家分类</h5>
        <BusinessEcharts v-if="showFlag" />
      </div>
    </div>
  </div>
</template>

<script>
import ZhongguancunTitle from '@/components/ZhongguancunTitle.vue'
import EnterpriseEcharts from '@/components/zhongguancunIndustry/EnterpriseEcharts.vue'
import BusinessEcharts from '@/components/zhongguancunIndustry/BusinessEcharts.vue'
import HomeIcon from '@/components/HomeIcon.vue'
import ICountUp from 'vue-countup-v2';
import { getOverview } from '@/api/zgc';

export default {
  data() {
    return {
      showFlag: false,
      settleArr: this.$store.state.zhongguancunIndustry.settleArr
    }
  },
  methods: {
    async getData() {
      const res = await getOverview()
      const { data, msg } = res;
      let itemData = {};
      if (msg == 'success' && data.length > 0) {
        itemData = data[0]
        for (let i in itemData) {
          this.settleArr.map((item) => {
            if (i == item.key) {
              item.num = Number(itemData[i])
            }
          })
        }
        console.log('园区总览数据', itemData)
      }
    },
  },
  mounted() {
    this.getData()
    setTimeout(() => {
      this.showFlag = true
    }, 800);
  },
  components: {
    ZhongguancunTitle,
    EnterpriseEcharts,
    BusinessEcharts,
    HomeIcon,
    ICountUp
  }
}
</script>

<style lang="less" scoped>

</style>